import { Controller } from "stimulus";

import Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    filename: String,
    id: Number,
  };

  connect() {}

  delete() {
    Rails.ajax({
      url: `/admin/maps.js?delete=${this.idValue}`,
      type: "get",
      success: (_response) => {
        this.element.remove();
      },
      error: (response) => {
        alert("Error: " + response);
      },
    });
  }

  generateZoomify() {
    Rails.ajax({
      url: `/admin/maps.js?generate_zoomify=${this.idValue}`,
      type: "get",
      success: (_response) => {
        alert("Background job to generate Zoomify created!");
      },
      error: (response) => {
        alert("Error: " + response);
      },
    });
  }

  rename() {
    try {
      const newFilename = prompt("Enter new filename:", this.filenameValue);

      if (newFilename) {
        window.location = `/admin/maps?rename=${
          this.idValue
        }&new_filename=${encodeURIComponent(newFilename)}`;
      } else {
        throw `Invalid filename`;
      }
    } catch (e) {
      alert("Error:\n\n" + e.toString());
    }
  }

  rotate() {
    const validAngles = [90, 180, 270];

    try {
      const angle = prompt("Angle to rotate: 90 (CW), 180, 270 (90 CCW)", "");

      if (validAngles.includes(parseInt(angle))) {
        window.location = `/admin/maps?rotate=${this.idValue}&angle=${angle}`;
      } else {
        throw `Invalid angle, valid angles are: ${validAngles.join(", ")}`;
      }
    } catch (e) {
      alert("Error:\n\n" + e.toString());
    }
  }

  setPublic() {
    Rails.ajax({
      url: `/admin/maps.js?set_acl=${this.idValue}`,
      type: "get",
      success: (_response) => {
        alert("Map set to public!");
      },
      error: (response) => {
        alert("Error: " + response);
      },
    });
  }
}
