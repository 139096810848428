import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "category",
    "currentAddress",
    "currentAddressRow",
    "email",
    "message",
    "submit",
  ];

  connect() {
    this.validate();
  }

  validate() {
    this.submitTarget.disabled = !this._validate();
  }

  _validate() {
    const email = this.emailTarget.value;
    const message = this.messageTarget.value;
    const category = this.categoryTarget.value;
    const currentAddress = this.currentAddressTarget.value;

    if (category === "photo_search") {
      this.currentAddressRowTarget.style.display = "block";
    } else {
      this.currentAddressRowTarget.style.display = "none";
    }

    if (email.length < 3) {
      return false;
    }

    if (message.length < 3) {
      return false;
    }

    if (category === "select") {
      return false;
    }

    return true;
  }
}
