export const source = (geoJSON) => {
  return {
    type: "geojson",
    data: geoJSON,
  };
};

export const layer = {
  id: "county",
  type: "line",
  source: "county",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "#888",
    "line-width": 2,
  },
};
