import React from "react";

import Map from "./Map";
import CoverageLayer from "./maps/CoverageLayer";

const CoverageMap = () => {
  return (
    <Map bounds={[-124.848974, 24.396308, -66.885444, 49.384358]} minZoom={3} maxZoom={6}>
      <CoverageLayer />
    </Map>
  );
};

import renderer from "../shared/renderer";
renderer(CoverageMap);
