import { useEffect } from "react";

function layerMatches(layer, id, source) {
  if (typeof id !== "undefined") {
    if (id instanceof RegExp) {
      if (layer.id.match(id)) {
        return true;
      }
    } else {
      if (layer.id === id) {
        return true;
      }
    }
  }

  if (typeof source !== "undefined") {
    if (source instanceof RegExp) {
      if (layer.source.match(source)) {
        return true;
      }
    } else {
      if (layer.source === source) {
        return true;
      }
    }
  }
}

function useCoverageSelect(
  map,
  satelliteActive,
  activeCoverage,
  setActiveCoverage
) {
  function hideLayers({ id, source } = {}) {
    if (!map.current) return;

    map.current.getStyle().layers.forEach((layer) => {
      if (layerMatches(layer, id, source)) {
        map.current.setLayoutProperty(layer.id, "visibility", "none");
      }
    });
  }

  function showLayers({ id, source } = {}) {
    if (!map.current) return;

    map.current.getStyle().layers.forEach((layer) => {
      if (layerMatches(layer, id, source)) {
        map.current.setLayoutProperty(layer.id, "visibility", "visible");
      }
    });
  }

  useEffect(() => {
    if (!map.current) return;
    if (satelliteActive === null) return;

    if (activeCoverage) {
      hideLayers({ id: /coverage-layer-/ });
      showLayers({ id: `coverage-layer-${activeCoverage}` });

      if (parseInt(activeCoverage) > 0) {
        hideLayers({ id: "satellite-satellite" });
      } else if (activeCoverage == "Modern") {
        showLayers({ id: "satellite-satellite" });
      }
    } else {
      hideLayers({ id: /coverage-layer-/ });
    }
  }, [activeCoverage]);

  useEffect(() => {
    if (!map.current) return;
    if (satelliteActive === null) return;

    if (satelliteActive) {
      showLayers({ id: /satellite-/ });
    } else {
      setActiveCoverage(null);
      hideLayers({ id: /satellite-/ });
      hideLayers({ id: /coverage-layer-/ });
    }
  }, [satelliteActive]);
}

export default useCoverageSelect;
