import React from "react";

export default function RasterCoverageSelector({
  checked,
  coverages,
  onChange,
  onSelect,
}) {
  return (
    <div>
      <label className="flex items-center font-normal text-xs space-x-1 mb-0">
        <input onChange={onChange} checked={!!checked} type="checkbox" />
        <div className="select-none">Imagery</div>
      </label>
      {checked && (
        <select
          onChange={(e) => {
            let id = e.target.value;
            onSelect(id);

            _vaTrack("map.raster_coverage_selected", { event_value: id });
          }}
          className="p-1 mt-1 text-xs"
        >
          <option key={-1} value={null}>
            Modern
          </option>

          {coverages.map((coverage) => (
            <option key={coverage.id} value={coverage.id}>
              {coverage.name}
            </option>
          ))}
        </select>
      )}
    </div>
  );
}
