import React, { useContext, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import maplibregl from "!maplibre-gl";

import { MapContext } from "../Map";

export default function PhotoPin({
  activeCoverageId,
  imageId,
  imageThumbnailUrl,
  imageUrl,
  draggable,
  location,
}) {
  const map = useContext(MapContext);
  const marker = useRef(null);

  const [pinMoved, setPinMoved] = useState(false);
  const [pinLat, setPinLat] = useState(location.lat);
  const [pinLng, setPinLng] = useState(location.lng);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    function handleFullScreenChange(_event) {
      setIsFullScreen(!!document.fullscreenElement);
    }

    window.addEventListener("fullscreenchange", handleFullScreenChange);
    return () =>
      window.removeEventListener("fullscreenchange", handleFullScreenChange);
  }, []);

  useHotkeys(
    "l",
    () => {
      if (!map || !marker.current) return;

      let input = prompt("Enter lat/lng (e.g., 41.365043, -82.387755)");

      if (input) {
        var parsed = input.split(",");
        var latlng = { lat: parseFloat(parsed[0]), lng: parseFloat(parsed[1]) };

        moved(marker.current, latlng);
        map.flyTo({ center: latlng, zoom: 14 });
      }
    },
    [map, marker]
  );

  function save(imageId, pinLat, pinLng, activeCoverageId) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("[name=csrf-token]").content,
      },
      body: JSON.stringify({
        lat: pinLat,
        lng: pinLng,
        rasterCoverageId: activeCoverageId,
      }),
    };

    fetch(`/photos/${imageId}/pin`, requestOptions)
      .then((response) => {
        if (response.status == 200) {
          _vaTrack("photo.pin");

          setPinMoved(false);

          swal({
            title: "Photo Pinned",
            text: "Thanks for updating this photo's location!",
            type: "success",
          });
        } else {
          swal({
            title: "Error",
            text: "Sorry, there was an error updating this photo's location.",
            type: "error",
          });
        }
      })
      .catch((_error) =>
        swal({
          title: "Error",
          text: "Sorry, there was an error updating this photo's location.",
          type: "error",
        })
      );
  }

  function moved(marker, lngLat) {
    setPinMoved(true);
    setPinLat(lngLat.lat);
    setPinLng(lngLat.lng);
    marker.setLngLat(lngLat);
  }

  useEffect(() => {
    if (!map) return;

    marker.current = new maplibregl.Marker({
      draggable,
      color: "#56b3d2",
    })
      .setLngLat(location)
      .addTo(map);

    const popup = new maplibregl.Popup({ offset: 50, anchor: "right" }).setHTML(
      `<img src="${imageThumbnailUrl}" />`
    );

    marker.current.setPopup(popup);

    if (draggable) {
      marker.current.on("dragend", () => {
        moved(marker.current, marker.current.getLngLat());
      });

      map.on("contextmenu", (e) => {
        e.preventDefault();

        moved(marker.current, e.lngLat);
      });
    }
  }, [map]);

  return (
    <>
      {isFullScreen && (
        <div className="absolute bottom-0 left-0 m-3 shadow-md ">
          <img className="rounded-md" src={imageUrl} width="500" height="328" />
        </div>
      )}
      {pinMoved && (
        <button
          onClick={() => save(imageId, pinLat, pinLng, activeCoverageId)}
          className="btn w-24 justify-center absolute bottom-0 right-0 left-0 mx-auto my-2"
        >
          Save Pin
        </button>
      )}
    </>
  );
}
