import { Controller } from "stimulus";

import Litepicker from "litepicker";

export default class extends Controller {
  static values = {
    submit: Boolean,
  };

  connect() {
    this.picker = new Litepicker({
      element: this.element,
      firstDay: 0,
      singleMode: true,
    });

    this.picker.on("selected", () => {
      if (this.submitValue) {
        this.element.closest("form").submit();
      }
    });
  }
}
