import React from "react";

import Map from "./Map";
import TillerPreviewLayer from "./maps/TillerPreviewLayer";

const RasterCoverageMap = ({
  id,
  minZoom,
  maxZoom,
  bounds,
  gcps,
  frames,
  seamlinesUrl,
  footprintUrl,
  tilesUrl,
}) => {
  return (
    <Map
      loader={false}
      bounds={bounds}
      fitBoundsOptions={{
        padding: 50,
      }}
      showLayerToggle={false}
      maxZoom={maxZoom}
      showZoomLevel
    >
      <TillerPreviewLayer
        id={id}
        minZoom={minZoom}
        maxZoom={maxZoom}
        bounds={bounds}
        tilesUrl={tilesUrl}
        footprintUrl={footprintUrl}
        seamlinesUrl={seamlinesUrl}
        gcps={gcps}
        frames={frames}
      />
    </Map>
  );
};

import renderer from "../shared/renderer";
renderer(RasterCoverageMap);
