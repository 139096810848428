import React, { useContext, useEffect } from "react";

import { MapContext } from "../Map";

export default function USGSLayer() {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    map.addSource("usgs", {
      type: "raster",
      scheme: "tms",
      tiles: [
        "https://cdn.vintageaerial.io/file/va-tiles-public/VZC00/20210701121800/{z}/{x}/{y}",
      ],
      minzoom: 6,
      maxzoom: 17,
      tileSize: 512,
    });

    map.addLayer(
      {
        id: "usgs",
        type: "raster",
        source: "usgs",
      },
      "satellite-Tunnel"
    );
  });

  return <></>;
}
