import React from "react";

import Map from "./Map";
import PLSSBoundaryLayer from "./maps/PLSSBoundaryLayer";

import geojsonExtent from "@mapbox/geojson-extent";

const PLSSTownshipMap = ({ geoJson, geoJsonUrl }) => {
  return (
    <Map
      bounds={geojsonExtent(geoJson)}
      fitBoundsOptions={{
        padding: 25,
      }}
    >
      <PLSSBoundaryLayer geoJsonUrl={geoJsonUrl} />
    </Map>
  );
};

import renderer from "../shared/renderer";
renderer(PLSSTownshipMap);
