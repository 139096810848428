import React, { useEffect, useState } from "react";
import clsx from "clsx";
import useSWR from "swr";

const fetcher = (...args) => fetch(...args).then((res) => res.json());

function sortByDirectionAndNumber(elements) {
  return elements.sort((a, b) => {
    const aParts = a.split(" ");
    const bParts = b.split(" ");
    if (aParts[1] < bParts[1]) return -1;
    if (aParts[1] > bParts[1]) return 1;
    if (aParts[0] < bParts[0]) return -1;
    if (aParts[0] > bParts[0]) return 1;
    return 0;
  });
}

export default function PLSSSelector({}) {
  const [selectedState, setSelectedState] = useState(null);
  const [selectedPrincipalMeridian, setSelectedPrincipalMeridian] =
    useState(null);
  const [selectedTownship, setSelectedTownship] = useState(null);
  const [selectedRange, setSelectedRange] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);

  const { data, error, isLoading } = useSWR(
    `/api/v1/plss.json?state=${[selectedState].join("")}&principal_meridian=${[
      selectedPrincipalMeridian,
    ].join("")}`,
    fetcher,
  );

  if (isLoading) return null;
  if (error) return <div>Error</div>;

  // only return unique townships, given by the townshop method:
  let townships = Array.from(
    new Set(data._townships.map((township) => township.township)),
  );

  // sort the townships based on the second part of the string first, then the first part (e.g., 100 N)
  townships = sortByDirectionAndNumber(townships);

  let ranges = Array.from(
    new Set(
      data._townships
        .filter((township) => {
          if (selectedTownship) {
            return township.township === selectedTownship;
          } else {
            return true;
          }
        })
        .map((township) => township.range),
    ),
  );

  ranges = sortByDirectionAndNumber(ranges);

  let selectedTownshipObject = data._townships.find(
    (township) =>
      township.township === selectedTownship &&
      township.range === selectedRange,
  );

  let url = undefined;
  if (selectedTownshipObject) {
    url = `/photos/plss/townships/${selectedTownshipObject.id}`;

    if (selectedSection) {
      url = `${url}/sections/${selectedSection}`;
    }
  }

  return (
    <div className="w-full flex justify-between items-end text-xs sm:text-base">
      <div>
        <label className="text-sm">State</label>
        <select
          value={selectedState}
          onChange={(e) => setSelectedState(e.target.value)}
        >
          {data &&
            data.states.map((state) => <option key={state}>{state}</option>)}
        </select>
      </div>
      <div>
        <label className="text-sm">
          <span className="hidden sm:inline-block">Principal</span> Meridian
        </label>
        <select
          className="w-[60px] sm:w-full truncate"
          value={selectedPrincipalMeridian}
          onChange={(e) => setSelectedPrincipalMeridian(e.target.value)}
        >
          {data &&
            data.principal_meridians.map((principalMeridian) => (
              <option key={principalMeridian}>
                {principalMeridian &&
                  principalMeridian.replace(" Meridian", "")}
              </option>
            ))}
        </select>
      </div>
      <div>
        <label className="text-sm">Township</label>
        <select
          value={selectedTownship}
          onChange={(e) => setSelectedTownship(e.target.value)}
        >
          {townships &&
            townships.map((township) => (
              <option key={township}>{township}</option>
            ))}
        </select>
      </div>
      <div>
        <label className="text-sm">Range</label>
        <select
          value={selectedRange}
          onChange={(e) => setSelectedRange(e.target.value)}
        >
          {ranges &&
            ranges.map((range) => <option key={range}>{range}</option>)}
        </select>
      </div>
      <div>
        <label className="text-sm">Section</label>
        <select
          value={selectedSection}
          onChange={(e) => setSelectedSection(e.target.value)}
        >
          {data &&
            data.sections.map((section) => (
              <option key={section}>{section}</option>
            ))}
        </select>
      </div>
      <div>
        <button
          className="btn self-start"
          disabled={!selectedTownshipObject}
          onClick={() => {
            window.location = url;
          }}
        >
          Go
        </button>
      </div>
    </div>
  );
}
import renderer from "../shared/renderer";
renderer(PLSSSelector);
