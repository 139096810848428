import React, { useState, useRef, useEffect } from "react";

import useMobileDetect from "use-mobile-detect-hook";

import maplibregl from "!maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

import LayerToggle from "./maps/LayerToggle";
import RasterCoverageSelector from "./maps/RasterCoverageSelector";
import Spinner from "./Spinner";
import { loadCoverages } from "./maps/layers/coverages";

import useCoverageSelect from "../shared/useCoverageSelect";

export const MapContext = React.createContext(null);

export default function Map({
  allowExpand,
  bounds,
  center,
  children,
  coverages,
  fitBoundsOptions,
  loader,
  maxZoom,
  minZoom,
  setActiveCoverageId,
  showLayerToggle,
  showZoomLevel,
  style,
  zoom,
}) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const zoomLevel = useRef(null);

  const detectMobile = useMobileDetect();

  const [supported, setSupported] = useState(true);
  const [loading, setLoading] = useState(true);

  const [coverageActive, setCoverageActive] = useState(null);
  const handleCoverageClick = () => setCoverageActive(!coverageActive);

  const [satelliteActive, setSatelliteActive] = useState(null);
  const handleSatelliteClick = () => {
    setSatelliteActive(!satelliteActive);
  };

  const [activeCoverage, setActiveCoverage] = useState(null);
  const handleSatelliteSelect = (coverage) => {
    setActiveCoverage(coverage);
    setActiveCoverageId(coverage);
  };

  useEffect(() => {
    if (map.current) return; // initialize map only once

    let options = {
      container: mapContainer.current,
      style: style || "/map/style.json",
      trackResize: detectMobile.isMobile() ? false : true,
    };

    if (bounds) {
      options.bounds = bounds;
    }

    if (fitBoundsOptions) {
      options.fitBoundsOptions = fitBoundsOptions;
    }

    if (center) {
      options.center = center;
    }

    if (minZoom) {
      options.minZoom = minZoom;
    }

    if (maxZoom) {
      options.maxZoom = maxZoom;
    }

    if (zoom) {
      options.zoom = zoom;
    }

    if (!maplibregl.supported()) {
      setSupported(false);
      _vaTrack("map.unsupported");
      return;
    }

    map.current = new maplibregl.Map(options);
    map.current.setLoading = setLoading;

    map.current.on("idle", (e) => {
      setLoading(false);
    });

    document.addEventListener("webkitfullscreenchange", () =>
      setTimeout(() => map.current.resize(), 0)
    );

    map.current.on("load", () => {
      loadCoverages(map, coverages);
    });

    if (showZoomLevel) {
      let currentZoom, currentZoomText, currentPosition, currentPositionText;

      let updateZoomAndPosition = () => {
        currentZoom = map.current.getZoom().toFixed(1);

        if (currentPosition) {
          let lat = currentPosition.lat.toFixed(4);
          let lng = currentPosition.lng.toFixed(4);
          let coords = `${lat},${lng}`;

          currentPositionText = coords;
        }

        zoomLevel.current.innerText = `${currentZoom} ${currentPositionText}`;
      };

      map.current.on("zoom", () => {
        updateZoomAndPosition();
      });

      map.current.on("mousemove", (e) => {
        currentPosition = e.lngLat.wrap();
        updateZoomAndPosition();
      });
    }

    if (!detectMobile.isMobile()) {
      if (allowExpand) {
        map.current.addControl(new maplibregl.FullscreenControl(), "top-left");
      }

      map.current.addControl(
        new maplibregl.NavigationControl({ showCompass: false }),
        "top-left"
      );
    }

    // disable map rotation using right click + drag
    map.current.dragRotate.disable();

    // disable map rotation using touch rotation gesture
    map.current.touchZoomRotate.disableRotation();
  });

  useCoverageSelect(map, satelliteActive, activeCoverage, setActiveCoverage);

  useEffect(() => {
    window.addEventListener("center-map", (e) => {
      map.current.setCenter([e.detail.lng, e.detail.lat]);
      map.current.zoomTo(16);
      setSatelliteActive(true);
    });

    return () => {
      window.removeEventListener("center-map");
    };
  }, [map]);

  if (!supported) {
    return (
      <div className="bg-neutral-100 text-sm text-neutral-500 text-center p-8 h-full w-full flex items-center">
        Sorry, our maps are not supported by your browser.
      </div>
    );
  }

  return (
    <MapContext.Provider value={map.current}>
      <div ref={mapContainer} className="w-full h-full">
        {showZoomLevel && (
          <div
            ref={zoomLevel}
            className="absolute left-0 bottom-0 z-50 p-2 text-halo font-bold"
          ></div>
        )}
        {showLayerToggle && (
          <div className="maplibregl-ctrl-group absolute z-50 top-[10px] right-[10px] px-2 py-1 bg-white space-x-3 flex flex-row items-center">
            {coverages && coverages.length > 0 ? (
              <RasterCoverageSelector
                coverages={coverages}
                checked={satelliteActive}
                onChange={handleSatelliteClick}
                onSelect={handleSatelliteSelect}
              />
            ) : (
              <LayerToggle
                checked={satelliteActive}
                onChange={handleSatelliteClick}
              >
                Imagery
              </LayerToggle>
            )}
          </div>
        )}
        {loader != false && loading && (
          <div className="absolute inset-0 z-50 grid place-items-center bg-white/25">
            <div className="w-24 h-24">
              <Spinner />
            </div>
          </div>
        )}
        {children}
      </div>
    </MapContext.Provider>
  );
}

Map.defaultProps = {
  showLayerToggle: true,
};
