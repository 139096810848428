import React, { useContext, useEffect } from "react";
import maplibregl from "!maplibre-gl";

import { MapContext } from "../Map";

export default function RollLayer({
  rollGeoJsonUrl,
  rollGeoJson,
  hideImageId,
  onPlacedClick,
  hideInterpolated,
}) {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    let rollDataSource = rollGeoJson ? JSON.parse(rollGeoJson) : rollGeoJsonUrl;

    map.addSource("roll", {
      type: "geojson",
      data: rollDataSource,
    });

    map.addLayer({
      id: "roll-flightpath",
      type: "line",
      source: "roll",
      paint: {
        "line-color": "#ff0000",
        "line-width": 2,
      },
      filter: ["==", "type", "Roll"],
    });

    map.addLayer({
      id: "images-placed",
      type: "circle",
      source: "roll",
      paint: {
        "circle-radius": ["interpolate", ["linear"], ["zoom"], 8, 2, 16, 8],
        "circle-color": "#56b3d2",
        "circle-stroke-width": 1,
        "circle-stroke-color": "#fff",
      },
      filter: [
        "all",
        ["==", "type", "Image"],
        ["!=", "precision", "flight_path_interpolation"],
        hideImageId ? ["!=", "id", hideImageId] : [">=", "id", 0],
      ],
    });

    map.addLayer({
      id: "images-placed-text",
      type: "symbol",
      source: "roll",
      minzoom: 12,
      filter: [
        "all",
        ["==", "type", "Image"],
        ["!=", "precision", "flight_path_interpolation"],
      ],
      paint: {
        "text-color": "#ffffff",
      },
      layout: {
        "text-field": "{number}",
        "text-size": 7,
      },
    });

    if (rollGeoJson) {
      const lineFeature = JSON.parse(rollGeoJson).features.find(
        (f) => f.geometry && f.geometry.type == "LineString"
      );

      if (lineFeature) {
        map.addLayer({
          id: "roll-flightpath-start",
          type: "circle",
          source: {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: lineFeature.geometry.coordinates[0],
              },
            },
          },
          paint: {
            "circle-radius": 5,
            "circle-color": "#ff0000",
          },
        });
      }
    }

    if (!hideInterpolated) {
      map.addLayer({
        id: "images-interpolated",
        type: "circle",
        source: "roll",
        paint: {
          "circle-radius": 3,
          "circle-color": "#ff0000",
        },
        filter: [
          "all",
          ["==", "type", "Image"],
          ["==", "precision", "flight_path_interpolation"],
        ],
      });
    }

    map.on("click", "images-placed", function (e) {
      if (onPlacedClick) {
        onPlacedClick(e);
        return;
      }

      var coordinates = e.features[0].geometry.coordinates.slice();
      var properties = e.features[0].properties;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      new maplibregl.Popup()
        .setLngLat(coordinates)
        .setHTML(
          `<img src="${properties.thumbnailUrl}" width="300" height="197">`
        )
        .addTo(map);
    });
  }, [map]);

  return <></>;
}
