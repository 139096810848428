import React from "react";
import ReactDOM from "react-dom";

export default (component) => {
  document.addEventListener("DOMContentLoaded", () => {
    const selector = `[data-react-component='${component.name}']`;
    var elements = document.querySelectorAll(selector);

    Array.prototype.forEach.call(elements, function (element, _) {
      let props = {};
      try {
        props = JSON.parse(element.dataset.reactProps);
      } catch (e) {}

      ReactDOM.render(React.createElement(component, props), element);
    });
  });
};
