export function loadCoverages(map, coverages) {
  if (coverages && coverages.length > 0) {
    coverages.forEach((coverage) => {
      map.current.addSource(`coverage-${coverage.id}`, {
        type: "raster",
        scheme: "tms",
        tiles: [coverage.tilesUrl],
        minzoom: coverage.minZoom,
        maxzoom: coverage.maxZoom,
        tileSize: window.devicePixelRatio > 1 ? 128 : 256,
        attribution: coverage.attribution || "",
      });

      map.current.addLayer(
        {
          id: `coverage-layer-${coverage.id}`,
          type: "raster",
          source: `coverage-${coverage.id}`,
          layout: {
            visibility: "none",
          },
        },
        "satellite-Tunnel"
      );
    });
  }
}
