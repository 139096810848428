import React, { useContext, useEffect } from "react";

import { circle } from "@turf/turf";

import { MapContext } from "../Map";

export default function SearchLocation({ allowDrag, location }) {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    const canvas = map.getCanvasContainer();

    const geojson = {
      type: "FeatureCollection",
      features: [circle(location, 1.5, { steps: 80, units: "miles" })],
    };

    function onMove(e) {
      const coords = e.lngLat;

      // Set a UI indicator for dragging.
      canvas.style.cursor = "grabbing";

      geojson.features[0] = circle([coords.lng, coords.lat], 1.5, {
        steps: 80,
        units: "miles",
      });
      map.getSource("point").setData(geojson);
    }

    function onUp(e) {
      canvas.style.cursor = "";

      // Unbind mouse/touch events
      map.off("mousemove", onMove);
      map.off("touchmove", onMove);

      const coords = e.lngLat;
      document.getElementById("lat").value = coords.lat;
      document.getElementById("lng").value = coords.lng;
      document.getElementById("q").value = "";
      document.getElementById("lat").closest("form").submit();
    }

    map.addSource("point", {
      type: "geojson",
      data: geojson,
    });

    map.addLayer({
      id: "point",
      type: "fill",
      source: "point",
      paint: {
        "fill-color": "#56b3d2",
        "fill-opacity": 0.35,
      },
    });

    const img = new Image(48, 48);
    img.onload = () => map.addImage("marker", img);
    img.src = `data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><path fill="white" fill-opacity="0.75" d="M23.822 20.88l-6.353-6.354c.93-1.465 1.467-3.2 1.467-5.059.001-5.219-4.247-9.467-9.468-9.467s-9.468 4.248-9.468 9.468c0 5.221 4.247 9.469 9.468 9.469 1.768 0 3.421-.487 4.839-1.333l6.396 6.396 3.119-3.12zm-20.294-11.412c0-3.273 2.665-5.938 5.939-5.938 3.275 0 5.94 2.664 5.94 5.938 0 3.275-2.665 5.939-5.94 5.939-3.274 0-5.939-2.664-5.939-5.939z"/></svg>`;

    map.addLayer({
      id: "search-area-marker",
      type: "symbol",
      source: {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: location,
              },
            },
          ],
        },
      },
      layout: {
        "icon-image": "marker",
        "icon-size": 0.5,
      },
    });

    if (allowDrag) {
      map.on("mouseenter", "point", () => {
        map.setPaintProperty("point", "fill-color", "#3bb2d0");
        canvas.style.cursor = "move";
      });

      map.on("mouseleave", "point", () => {
        map.setPaintProperty("point", "fill-color", "#56b3d2");
        canvas.style.cursor = "";
      });

      map.on("mousedown", "point", (e) => {
        // Prevent the default map drag behavior.
        e.preventDefault();

        canvas.style.cursor = "grab";

        map.on("mousemove", onMove);
        map.once("mouseup", onUp);
      });

      map.on("touchstart", "point", (e) => {
        if (e.points.length !== 1) return;

        // Prevent the default map drag behavior.
        e.preventDefault();

        map.on("touchmove", onMove);
        map.once("touchend", onUp);
      });
    }
  }, [map]);

  return <></>;
}
