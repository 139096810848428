import { Controller } from "stimulus";

export default class extends Controller {
  static values = { imageId: Number };

  select(e) {
    if (e.detail && e.detail.imageId == this.imageIdValue) {
      this.element.classList.add("selected");
      this.element.scrollIntoView({ behavior: "smooth" });
    } else {
      this.element.classList.remove("selected");
    }
  }
}
