import React from "react";

import Map from "./Map";
import SearchLocation from "./maps/SearchLocation";

const SearchMap = ({ location, coverages }) => {
  return (
    <Map
      loader={false}
      allowExpand={false}
      fitBoundsOptions={{
        padding: 50,
      }}
      coverages={coverages}
      center={location}
      zoom={10}
    >
      <SearchLocation location={location} allowDrag />
    </Map>
  );
};

import renderer from "../shared/renderer";
renderer(SearchMap);
