import React from "react";

export default function LayerToggle({ checked, children, onChange }) {
  return (
    <label className="flex items-center font-normal text-xs space-x-1 mb-0 select-none cursor-pointer">
      <input onChange={onChange} checked={!!checked} type="checkbox" />
      <div>{children}</div>
    </label>
  );
}
