import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["current"];

  connect() {
    this.load();

    setInterval(() => {
      this.load();
    }, 60000);
  }

  load() {
    if (!this.chart) {
      this.createChart();
    }

    fetch("/admin/visitors.json")
      .then((response) => response.json())
      .then((json) => {
        this.updateVisitorsChart(json);
        this.currentTarget.innerHTML = json[json.length - 1].sessions_count;
      });
  }

  createChart() {
    this.chart =
      window.ApexCharts &&
      new ApexCharts(document.getElementById("chart-visitors-bg"), {
        chart: {
          type: "area",
          fontFamily: "inherit",
          height: 40.0,
          sparkline: {
            enabled: true,
          },
          animations: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          opacity: 0.15,
          type: "solid",
        },
        stroke: {
          width: 2,
          lineCap: "round",
          curve: "smooth",
        },
        series: [],
        grid: {
          strokeDashArray: 4,
        },
        xaxis: {
          datetimeUTC: true,
          labels: {
            padding: 0,
          },
          tooltip: {
            enabled: false,
          },
          axisBorder: {
            show: false,
          },
          type: "datetime",
        },
        yaxis: {
          labels: {
            padding: 4,
          },
        },
        labels: [],
        colors: ["#206bc4"],
        legend: {
          show: false,
        },
      });
    this.chart.render();
  }

  updateVisitorsChart(data) {
    if (this.chart) {
      this.chart.updateOptions({
        series: [
          {
            name: "Visitors Online",
            data: data.map((item) => item.sessions_count),
          },
        ],
        labels: data.map((item) => Date.parse(item.hour)),
      });

      return;
    }
  }
}
