import React, { useContext, useEffect, useState } from "react";
import maplibregl from "!maplibre-gl";

import {
  ramp as countiesRamp,
  layer as countiesLayer,
} from "./layers/counties";
import { source as coverageSource } from "./layers/coverage";
import Legend from "./Legend";
import { MapContext } from "../Map";

export default function CoverageLayer({
  fips,
  stateAbbreviation,
  opacity = 0.5,
  outline = false,
}) {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    map.addSource("coverage", coverageSource);
    let overrides = { maxzoom: 9 };
    map.addLayer({ ...countiesLayer, ...overrides });
    map.setPaintProperty("counties", "fill-opacity", opacity);
    if (outline) {
      map.setPaintProperty("counties", "fill-outline-color", "#ffffff");
    }

    if (stateAbbreviation) {
      map.addSource("county-labels", {
        type: "geojson",
        data: `/api/v1/counties.geojson?state=${stateAbbreviation}`,
      });

      map.addLayer({
        id: "county-labels",
        type: "symbol",
        source: "county-labels",
        minzoom: 5,
        paint: {
          "text-color": "#ffffff",
        },
        allowOverlap: true,
        layout: {
          "text-field": ["get", "label"],
          "text-size": ["interpolate", ["linear"], ["zoom"], 6, 5, 8, 12],
        },
      });
    }

    if (fips) {
      map.setPaintProperty("counties", "fill-opacity", [
        "match",
        ["get", "FIPS"],
        fips,
        opacity,
        0,
      ]);
    }

    var popup = new maplibregl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    map.on("click", "counties", function (e) {
      if (e.features.length == 0) {
        return;
      }

      var properties = e.features[0].properties;
      window.location = properties.va_permalink;
    });

    map.on("mousemove", "counties", function (e) {
      if (e.features.length == 0) {
        return;
      }

      // Change the cursor style as a UI indicator.
      map.getCanvas().style.cursor = "pointer";

      var coordinates = e.lngLat;
      var properties = e.features[0].properties;
      var description = `<div><strong>${properties.NAME}, ${
        properties.STATE_NAME
      }</strong></div><div>${properties.va_images_count.toLocaleString()} photos</div>`;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      popup.setLngLat(coordinates).setHTML(description).addTo(map);
    });

    map.on("mouseleave", "counties", function () {
      map.getCanvas().style.cursor = "";
      popup.remove();
    });
  }, [map]);

  return (
    <>
      <Legend ramp={countiesRamp} />
    </>
  );
}
