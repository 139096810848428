import React, { useContext, useEffect } from "react";

import { MapContext } from "../Map";

export default function CountyBoundaryLayer({ countyGeoJsonUrl }) {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    map.addSource("county", {
      type: "geojson",
      data: countyGeoJsonUrl,
    });

    map.addLayer({
      id: "county",
      type: "line",
      source: "county",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "#888",
        "line-width": 2,
      },
    });
  }, [map]);

  return <></>;
}
