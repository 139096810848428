import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["code"];

  connect() {}

  format() {
    let ugly = this.codeTarget.value;

    try {
      var obj = JSON.parse(ugly);
      var pretty = JSON.stringify(obj, undefined, 2);
      this.codeTarget.value = pretty;

      return true;
    } catch(e) {
      alert(e);

      return false;
    }
  }

  _generateCode(len) {
    var text = "";
    var charset = "ACEFHJKMNPRTUVWXY3479";

    for (var i = 0; i < len; i++) {
      text += charset.charAt(Math.floor(Math.random() * charset.length));
    }

    return text;
  }
}
