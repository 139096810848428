export const ramp = {
  2: "#bdc9e0",
  10: "#74a9cf",
  20: "#188ec0",
  40: "#005c8e",
};

export const layer = {
  id: "coverage",
  type: "fill",
  source: "coverage",
  "source-layer": "coverage",
  paint: {
    "fill-opacity": 0.01,
    "fill-color": [
      "interpolate",
      ["linear"],
      ["get", "count"],
      ...Object.keys(ramp).flatMap((v, _) => [parseInt(v), ramp[v]]),
    ],
  },
};

export const source = {
  type: "vector",
  minzoom: 3,
  maxzoom: 11,
  bounds: [-180, -85, 150, 85],
  tiles: [
    "https://s3.amazonaws.com/va-tiles/coverage/{z}/{x}/{y}.pbf",
  ],
};
