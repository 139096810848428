import { Controller } from "stimulus";

import numeral from "numeral";

const centsToDollars = (cents) => {
  return numeral(cents / 100).format("$0,0.00");
};

export default class extends Controller {
  static targets = [
    "canvas",
    "canvasFrameId",
    "canvasMsrp",
    "canvasPrice",
    "canvasProductOutputOptionId",
    "canvasSizeId",
    "frame",
    "frameAddToCartButton",
    "frameFrameId",
    "frameMsrp",
    "framePrice",
    "frameSizeId",
    "outOfStockMessage",
  ];

  static values = {
    canvasCornerUrls: Object,
    frameCornerUrls: Object,
    outOfStockSizeAndFrameIds: Array,
    prices: Object,
  };

  connect() {
    this.update();
  }

  update() {
    if (this.hasFrameSizeIdTarget) {
      this.updateFrames();
    }

    this.updateCanvas();
  }

  updateFrames() {
    const frameSizeId = parseInt(this.frameSizeIdTarget.value);
    const frameFrameId = parseInt(this.frameFrameIdTarget.value);
    const framePrice = this.pricesValue[`${frameSizeId}-${frameFrameId}`];
    this.framePriceTarget.textContent = centsToDollars(framePrice.price);

    if (framePrice.price != framePrice.msrp) {
      this.frameMsrpTarget.textContent = centsToDollars(framePrice.msrp);
    }

    let outOfStock = false;
    this.outOfStockSizeAndFrameIdsValue.forEach(([sizeId, frameId]) => {
      if (sizeId == frameSizeId && frameId == frameFrameId) {
        outOfStock = true;
      }
    });

    if (outOfStock) {
      this.outOfStockMessageTarget.style.display = "block";
      this.frameAddToCartButtonTarget.disabled = true;
    } else {
      this.outOfStockMessageTarget.style.display = "none";
      this.frameAddToCartButtonTarget.disabled = false;
    }

    this.frameTarget.style.backgroundImage = `url("${
      this.frameCornerUrlsValue[this.frameFrameIdTarget.value]
    }")`;
  }

  updateCanvas() {
    const canvasSizeId = this.canvasSizeIdTarget.value;
    const canvasFrameId = this.canvasFrameIdTarget.value;
    const canvasPrice = this.pricesValue[`${canvasSizeId}-${canvasFrameId}`];
    this.canvasPriceTarget.textContent = centsToDollars(canvasPrice.price);

    if (canvasPrice.price != canvasPrice.msrp) {
      this.canvasMsrpTarget.textContent = centsToDollars(canvasPrice.msrp);
    }

    this.canvasTarget.style.backgroundImage = `url("${
      this.canvasCornerUrlsValue[this.canvasProductOutputOptionIdTarget.value]
    }")`;
  }
}
