import React, { useContext, useEffect } from "react";
import maplibregl from "!maplibre-gl";

import { MapContext } from "../Map";

export default function UserGeotagsLayer({ userGeoJsonUrl }) {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    map.setLoading(true);

    fetch(userGeoJsonUrl)
      .then((response) => response.json())
      .then((data) => {
        map.addSource("user", {
          type: "geojson",
          data,
          cluster: true,
          clusterMaxZoom: 10, // Max zoom to cluster points on
          clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        });

        map.addLayer({
          id: "clusters",
          type: "circle",
          source: "user",
          filter: ["has", "point_count"],
          paint: {
            // Use step expressions (https://maplibre.org/maplibre-gl-js-docs/style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#51bbd6",
              100,
              "#f1f075",
              750,
              "#f28cb1",
            ],
            "circle-radius": [
              "step",
              ["get", "point_count"],
              20,
              100,
              30,
              750,
              40,
            ],
          },
        });

        map.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "user",
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-size": 12,
          },
          paint: {
            "text-color": [
              "step",
              ["get", "point_count"],
              "#ffffff",
              100,
              "#000000",
              750,
              "#000000",
            ],
          },
        });

        map.addLayer({
          id: "unclustered-point",
          type: "circle",
          source: "user",
          filter: ["!", ["has", "point_count"]],
          paint: {
            "circle-color": "#11b4da",
            "circle-radius": 4,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#fff",
          },
        });

        map.setLoading(false);
      });

    map.on("click", "clusters", function (e) {
      var features = map.queryRenderedFeatures(e.point, {
        layers: ["clusters"],
      });
      var clusterId = features[0].properties.cluster_id;

      map
        .getSource("user")
        .getClusterExpansionZoom(clusterId, function (err, zoom) {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
        });
    });

    map.on("click", "unclustered-point", function (e) {
      var coordinates = e.features[0].geometry.coordinates.slice();

      // Ensure that if the map is zoomed out such that
      // multiple copies of the feature are visible, the
      // popup appears over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      new maplibregl.Popup()
        .setLngLat(coordinates)
        .setHTML(
          `<a href="/photos/${e.features[0].properties.imageId}"><img src='https://cdn.vintageaerial.io/file/va-public/${e.features[0].properties.hashid}/500x.jpg' width='500' height='328' /></a>`
        )
        .addTo(map);
    });

    map.on("mouseenter", "clusters", function () {
      map.getCanvas().style.cursor = "pointer";
    });

    map.on("mouseleave", "clusters", function () {
      map.getCanvas().style.cursor = "";
    });
  });

  return <></>;
}
