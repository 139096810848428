import { Controller } from "stimulus";
import { formatDistanceStrict, parseISO } from "date-fns";

export default class extends Controller {
  connect() {
    this.datetime = parseISO(this.element.getAttribute("datetime"));

    this.timer = setInterval(() => this.update(), 10000);
    this.update();
  }

  disconnect() {
    clearInterval(this.timer);
  }

  update() {
    const now = new Date();
    var relativeTime = formatDistanceStrict(this.datetime, now, {
      addSuffix: true,
    });

    this.element.textContent = relativeTime;
  }
}
