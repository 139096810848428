import React from "react";

import Map from "./Map";
import ApproximatePhotoLocation from "./maps/ApproximatePhotoLocation";
import CountyBoundaryLayer from "./maps/CountyBoundaryLayer";
import PhotoPin from "./maps/PhotoPin";
import RollLayer from "./maps/RollLayer";

function showApproximatePhotoLocation(precision, allowPinMove) {
  if (allowPinMove) {
    return false;
  } else {
    if (precision == "flight_path_interpolation") {
      return true;
    } else {
      return false;
    }
  }
}

function showCountyBoundaryLayer(precision, allowPinMove) {
  if (allowPinMove) {
    return false;
  } else {
    if (precision == "county") {
      return true;
    } else {
      return false;
    }
  }
}

function showPhotoPin(precision, allowPinMove) {
  if (allowPinMove) {
    return true;
  } else {
    if (precision == "placed" || precision == "verified") {
      return true;
    } else {
      return false;
    }
  }
}

const PhotoMap = ({
  allowPinMove,
  countyGeoJsonUrl,
  coverages,
  imageId,
  imageLngLat,
  imageThumbnailUrl,
  imageUrl,
  imageVerified,
  precision,
  rollGeoJsonUrl,
  zoom,
}) => {

  let [activeCoverageId, setActiveCoverageId] = React.useState(null);

  return (
    <Map
      center={imageLngLat}
      zoom={zoom}
      maxZoom={18}
      loader={false}
      allowExpand={true}
      coverages={coverages}
      setActiveCoverageId={setActiveCoverageId}
      style="/map/style.json?streets=v2"
    >
      <RollLayer rollGeoJsonUrl={rollGeoJsonUrl} hideImageId={imageId} />

      {showCountyBoundaryLayer(precision, allowPinMove) ? (
        <CountyBoundaryLayer countyGeoJsonUrl={countyGeoJsonUrl} />
      ) : null}

      {showApproximatePhotoLocation(precision, allowPinMove) ? (
        <ApproximatePhotoLocation location={imageLngLat} />
      ) : null}

      {showPhotoPin(precision, allowPinMove) ? (
        <PhotoPin
          imageId={imageId}
          imageThumbnailUrl={imageThumbnailUrl}
          imageUrl={imageUrl}
          draggable={!imageVerified && allowPinMove}
          location={imageLngLat}
          activeCoverageId={activeCoverageId}
        />
      ) : null}
    </Map>
  );
};

import renderer from "../shared/renderer";
renderer(PhotoMap);
