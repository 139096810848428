import { Controller } from "stimulus";
import { CountUp } from "countup.js";

export default class extends Controller {
  static values = { number: Number, decimals: Number, suffix: String };

  initialize() {
    this.animated = false;
    this.intersectionObserver = new IntersectionObserver((entries) =>
      this.processIntersectionEntries(entries)
    );
  }

  connect() {
    this.intersectionObserver.observe(this.element);
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.element);
  }

  processIntersectionEntries(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !this.animated) {
        let value = this.numberValue;
        let decimals = this.decimalsValue;
        let suffix = this.suffixValue;

        let countUp = new CountUp(this.element, value, {
          useEasing: true,
          decimalPlaces: decimals,
          suffix: suffix,
        });
        countUp.start();

        this.animated = true;
      }
    });
  }
}
