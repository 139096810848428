import { Controller } from "stimulus";

import Litepicker from "litepicker";
import "litepicker/dist/plugins/ranges";

export default class extends Controller {
  static values = {
    submit: Boolean,
  };

  static targets = ["start", "end"];

  connect() {
    this.picker = new Litepicker({
      element: this.startTarget,
      elementEnd: this.endTarget,
      plugins: ["ranges"],
      singleMode: true,
      resetButton: true,
      dropdowns: {
        minYear: 2000,
        maxYear: null,
        months: true,
        years: true,
      },
    });

    this.picker.on("clear:selection", this._submit.bind(this));
    this.picker.on("selected", this._submit.bind(this));
  }

  _submit() {
    if (this.submitValue) {
      this.element.closest("form").submit();
    }
  }
}
