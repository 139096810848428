import React from "react";

import Map from "./Map";
import CoverageLayer from "./maps/CoverageLayer";

const StateCoverageMap = ({ fips, bounds, stateAbbreviation }) => {
  return (
    <Map bounds={bounds} minZoom={4} maxZoom={8}>
      <CoverageLayer
        fips={fips}
        stateAbbreviation={stateAbbreviation}
        opacity={0.9}
        outline
      />
    </Map>
  );
};

import renderer from "../shared/renderer";
renderer(StateCoverageMap);
