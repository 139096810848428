import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "checkbox",
    "ordersCountTotal",
    "contributionTotal",
    "percentTotal",
    "percentOfContributionTotal",
    "totalContribution",
  ];

  connect() {
    this.update();

    console.log(this.fooValue);
  }

  update() {
    let ordersCount = 0;
    let percentTotal = 0;
    let contributionTotal = 0;

    let currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    var percentFormatter = new Intl.NumberFormat("en-US", {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    });

    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        ordersCount += Number(checkbox.dataset.ordersCount);
        contributionTotal += Number(checkbox.dataset.contribution);
        percentTotal += Number(checkbox.dataset.percent) / 100.0;
      }
    });

    this.ordersCountTotalTarget.innerHTML = ordersCount;
    this.percentTotalTarget.innerHTML = percentFormatter.format(percentTotal);
    this.contributionTotalTarget.innerHTML =
      currencyFormatter.format(contributionTotal);

    let totalContribution = Number(
      this.totalContributionTarget.innerHTML.replace(/[^0-9.-]+/g, "")
    );

    this.percentOfContributionTotalTarget.innerHTML = percentFormatter.format(
      contributionTotal / totalContribution
    );
  }
}
