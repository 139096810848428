import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["code"];

  connect() {}

  generate() {
    this.codeTarget.value = this._generateCode(7);
  }

  _generateCode(len) {
    var text = "";
    var charset = "ACEFHJKMNPRTUVWXY3479";

    for (var i = 0; i < len; i++) {
      text += charset.charAt(Math.floor(Math.random() * charset.length));
    }

    return text;
  }
}
