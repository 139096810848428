import { Controller } from "stimulus";

import * as d3 from "d3";

import { geoAlbersUsa, geoPath } from "d3-geo";

import * as topojson from "topojson-client";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export default class extends Controller {
  static targets = ["swiper", "body", "name", "map"];
  static values = {
    mapDataUrl: String,
  };

  connect() {
    this.setupMap();
    this.setupSwiper();
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = undefined;
  }

  next() {
    this.swiper.slideNext();
  }

  prev() {
    this.swiper.slidePrev();
  }

  setupMap() {
    const width = 960;
    const height = 600;

    this.projection = geoAlbersUsa()
      .scale(1280)
      .translate([width / 2, height / 2]);

    const path = geoPath().projection(this.projection);

    const svg = d3.select(this.mapTarget);

    d3.json(this.mapDataUrlValue).then((us, error) => {
      if (error) throw error;

      svg
        .append("path")
        .datum(topojson.feature(us, us.objects.states))
        .attr("d", path);

      this.circle = svg.append("circle").attr("r", "10px");

      this.updateStoryDetails();
    });
  }

  setupSwiper() {
    this.swiper = new Swiper(this.swiperTarget, {
      slidesPerView: 1,
      paginationClickable: true,
      spaceBetween: 0,
      keyboardControl: true,
      preloadImages: false,
      lazy: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      runCallbacksOnInit: true,
      on: {
        transitionEnd: () => {
          const storyData = this.updateStoryDetails();

          const url = `/stories/${storyData.id}`;
          window.history.replaceState({}, "", url);
          _gaq.push(["_trackPageview", url]);
        },
      },
    });
  }

  updateStoryDetails() {
    let story = this.swiper.slides[this.swiper.activeIndex];
    let storyData = JSON.parse(story.dataset.story);

    this.bodyTarget.innerHTML = storyData.edited_body;
    this.nameTarget.innerHTML = `&mdash; ${storyData.name}`;

    const lonlat = [storyData.longitude, storyData.latitude];

    if (this.circle) {
      this.circle
        .transition()
        .duration(250)
        .attr("cx", () => {
          return this.projection(lonlat)[0];
        })
        .attr("cy", () => {
          return this.projection(lonlat)[1];
        })
        .attr("r", "20px")
        .attr("fill", "#56b3d2");
    }

    return storyData;
  }
}
