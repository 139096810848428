import React, { useContext, useEffect } from "react";

import { circle } from "@turf/turf";

import { MapContext } from "../Map";

export default function ApproximatePhotoLocation({ location }) {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    let _circle = circle(location, 1.5, { steps: 80, units: "miles" });

    map.addSource("circleData", {
      type: "geojson",
      data: _circle,
    });

    map.addLayer({
      id: "circle-fill",
      type: "fill",
      source: "circleData",
      paint: {
        "fill-color": "#56b3d2",
        "fill-opacity": 0.5,
      },
    });
  }, [map]);

  return <></>;
}
