import React from "react";
import BaseSearchBox from "./BaseSearchBox";

export default function SearchBox({ placeholder }) {
  return (
    <BaseSearchBox
      placeholder={
        placeholder ||
        "Search by address, keyword, county, state, or photo code"
      }
      onSelect={(item) => {
        window.location = item.link;
      }}
      types={"addresses,counties,states,photo_codes,keywords"}
    />
  );
}

import renderer from "../shared/renderer";
renderer(SearchBox);
