import { Controller } from "stimulus";

import * as d3 from "d3";

export default class extends Controller {
  static targets = ["container"];
  static values = { rolls: Array };

  initialize() {
    const circleSize = 8;
    const circleSizeHover = 10;
    const color = "#56b3d2";

    // set dimensions and margins for the chart
    const margin = { top: 15, right: 25, bottom: 0, left: 25 };
    const width = 768 - margin.left - margin.right;
    const height = 40 - margin.top - margin.bottom;

    // set up x and y scale
    const x = d3.scaleTime().range([0, width]);

    const svg = d3
      .select(this.containerTarget)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    let selected;

    const data = this.rollsValue.map((roll) => {
      if (roll.selected) selected = roll;

      return { ...roll, date: new Date(`${roll.year}-01-01`) };
    });

    // define x domain from min/max of data
    const xMin = new Date(d3.min(data, (d) => d.date));
    xMin.setFullYear(xMin.getFullYear() - 5);
    const xMax = new Date(d3.max(data, (d) => d.date));
    xMax.setFullYear(xMax.getFullYear() + 5);
    x.domain([xMin, xMax]);

    // add x axis
    const xAxis = svg
      .append("g")
      .attr("class", "axis")
      .call(
        d3
          .axisBottom(x)
          .ticks(d3.timeYear.every(5))
          .tickFormat(d3.timeFormat("%Y"))
          .tickSize(0)
      )
      .selectAll("text")
      .attr("y", 15)
      .attr("fill", "#666");

    // add colored background for selected data
    if (selected) {
      svg
        .append("rect")
        .attr("x", x.range()[0])
        .attr("y", -2)
        .attr("width", x(new Date(selected.year, 1, 1)) - x.range()[0] - 5)
        .attr("height", 4)
        .attr("fill", color);
    }

    svg
      .selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", (d) => x(d.date) + d.index * 10)
      .attr("r", circleSize)
      .attr("fill", (d) => (d.selected ? color : "white"))
      .attr("stroke", color)
      .attr("stroke-width", 2)
      .attr("cursor", "pointer")
      .on("click", function (_event, d) {
        window.location.href = d.permalink;
      })
      .on("mouseover", function () {
        d3.select(this)
          .transition()
          .duration(100)
          .attr("r", circleSizeHover)
          .attr("stroke", "white")
          .attr("fill", color);
      })
      .on("mouseout", function () {
        d3.select(this)
          .transition()
          .duration(100)
          .attr("r", circleSize)
          .attr("stroke", color)
          .attr("fill", (d) => (d.selected ? color : "white"));
      });
  }

  // dismiss() {
  //   Cookies.set(this.cookieValue, "true", { expires: 365 });
  //   this.dismissableTarget.remove();
  // }
}
