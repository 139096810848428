import React from "react";
import geojsonExtent from "@mapbox/geojson-extent";

import Map from "./Map";
import RollLayer from "./maps/RollLayer";
import SearchLocation from "./maps/SearchLocation";

const RollMap = ({ rollGeoJson, coverages, searchLocation }) => {
  const geoJson = JSON.parse(rollGeoJson);

  return (
    <Map
      loader={false}
      allowExpand={false}
      bounds={geojsonExtent(geoJson)}
      fitBoundsOptions={{
        padding: 50,
      }}
      coverages={coverages}
    >
      <RollLayer
        rollGeoJson={rollGeoJson}
        hideInterpolated={true}
        onPlacedClick={(e) => {
          const event = new CustomEvent("click-pin-on-map", {
            detail: { imageId: e.features[0].properties.id },
          });
          window.dispatchEvent(event);
        }}
      />
      {searchLocation && (<SearchLocation location={searchLocation}/>)}
    </Map>
  );
};

import renderer from "../shared/renderer";
renderer(RollMap);
