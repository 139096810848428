import React from "react";

import Map from "./Map";
import UserGeotagsLayer from "./maps/UserGeotagsLayer";
import USGSLayer from "./maps/USGSLayer";

const UserGeotagsMap = ({ bounds, userGeoJsonUrl }) => {
  return (
    <Map bounds={bounds}>
      <UserGeotagsLayer userGeoJsonUrl={userGeoJsonUrl} />
    </Map>
  );
};

import renderer from "../shared/renderer";
renderer(UserGeotagsMap);
