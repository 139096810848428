import { Controller } from "stimulus";

import Rails from "@rails/ujs";

export default class extends Controller {

  static values = {
    url: String,
  };

  suggest() {
    this.spinnerTarget.classList.remove("d-none");

    Rails.ajax({
      url: this.urlValue,
      type: "GET",
      success: (data) => {
      this.spinnerTarget.classList.add("d-none");
        this.textareaTarget.value = data.suggested_reply;
      },
    });
  }
}
