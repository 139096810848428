export const ramp = {
  100: "#bdc9e0",
  1000: "#74a9cf",
  7500: "#188ec0",
  15000: "#005c8e",
};

export const layer = {
  id: "counties",
  maxzoom: 7,
  type: "fill",
  source: "coverage",
  "source-layer": "counties",
  filter: [">", "va_images_count", 100],
  paint: {
    "fill-opacity": 0.01,
    "fill-color": [
      "interpolate",
      ["linear"],
      ["get", "va_images_count"],
      ...Object.keys(ramp).flatMap((v, _) => [parseInt(v), ramp[v]]),
    ],
  },
};
