import React from "react";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionText,
} from "@reach/combobox";
import debounceFn from "debounce-fn";

export default function SearchBox({ placeholder, onSelect, types }) {
  let options = {};
  const [searchTerm, setSearchTerm] = React.useState("");
  const results = useAutocompleteSearch(searchTerm, types);
  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <Combobox
      aria-label="Search"
      className="autocomplete"
      onSelect={(item) => onSelect(options[item])}
      openOnFocus
    >
      <div className="relative">
        <ComboboxInput
          className={"pl-10"}
          placeholder={placeholder}
          onChange={debounceFn(handleSearchTermChange, { wait: 250 })}
          onFocus={() => _vaTrack("autocomplete.focus")}
        />
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5"
            viewBox="0 0 24 24"
          >
            <path d="M23.809 21.646l-6.205-6.205c1.167-1.605 1.857-3.579 1.857-5.711 0-5.365-4.365-9.73-9.731-9.73-5.365 0-9.73 4.365-9.73 9.73 0 5.366 4.365 9.73 9.73 9.73 2.034 0 3.923-.627 5.487-1.698l6.238 6.238 2.354-2.354zm-20.955-11.916c0-3.792 3.085-6.877 6.877-6.877s6.877 3.085 6.877 6.877-3.085 6.877-6.877 6.877c-3.793 0-6.877-3.085-6.877-6.877z" />
          </svg>
        </div>
      </div>
      {results && (
        <ComboboxPopover portal={false}>
          {results.length > 0 && (
            <ComboboxList>
              {results.map((result) => {
                return (
                  <div className="category" key={result.title}>
                    <div className="category-header">
                      <div className="title">{result.title}</div>
                      <div className="attribution">{result.attribution}</div>
                    </div>
                    {result.hits &&
                      result.hits.map((result) => {
                        options[result.text] = result;

                        return (
                          <ComboboxOption
                            key={JSON.stringify(result)}
                            value={result.text}
                          >
                            <ComboboxOptionText />
                          </ComboboxOption>
                        );
                      })}
                  </div>
                );
              })}
            </ComboboxList>
          )}
        </ComboboxPopover>
      )}
    </Combobox>
  );
}
function useAutocompleteSearch(searchTerm, types) {
  const [results, setResults] = React.useState([]);

  React.useEffect(() => {
    if (searchTerm.trim() !== "") {
      let isFresh = true;
      fetchAutocomplete(searchTerm, types).then((result) => {
        if (isFresh) setResults(result);
      });
      return () => (isFresh = false);
    }
  }, [searchTerm]);

  return results;
}

const cache = {};

function fetchAutocomplete(value, types) {
  if (cache[value]) {
    return Promise.resolve(cache[value]);
  }

  return fetch(`/api/v1/autocomplete?q=${value}&types=${types}`)
    .then((res) => res.json())
    .then((result) => {
      cache[value] = result;
      return result;
    });
}

import renderer from "../shared/renderer";
renderer(SearchBox);
