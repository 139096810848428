import { Controller } from "stimulus";

import Swiper from "swiper/bundle";

import "swiper/css/bundle";

export default class extends Controller {
  static targets = ["prev", "next"];
  static values = {
    options: Object,
    previousImageUrl: String,
    previousImageSrc: String,
    nextImageUrl: String,
    nextImageSrc: String,
  };

  connect() {
    const isMobile = new Swiper().support.touch;

    if (!isMobile) {
      return;
    }

    this.swiper = new Swiper(this.element, {
      slidesPerView: 1,
      runCallbacksOnInit: false,
      on: {
        init: (swiper) => {
          if (this.previousImageUrlValue) {
            swiper.prependSlide(
              `<div class="swiper-slide" data-url="${this.previousImageUrlValue}"><img src="${this.previousImageSrcValue}" /></div>`
            );

            this.prevTarget.classList.remove("swiper-button-disabled");
          }

          if (this.nextImageUrlValue) {
            swiper.appendSlide(
              `<div class="swiper-slide" data-url="${this.nextImageUrlValue}"><img src="${this.nextImageSrcValue}" /></div>`
            );

            this.nextTarget.classList.remove("swiper-button-disabled");
          }
        },
        slideChangeTransitionEnd: () => {
          const newUrl =
            this.swiper.slides[this.swiper.activeIndex].dataset.url;

          if (newUrl) {
            window.location = newUrl;
          }
        },
      },
    });
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = undefined;
  }

  next() {
    this.swiper.slideNext();
  }

  prev() {
    this.swiper.slidePrev();
  }
}
