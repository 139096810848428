import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["message"];

  connect() {
    const stripe = Stripe(window.STRIPE_PUBLISHABLE_KEY);
    const elements = stripe.elements();

    const options = {
      amount: 17900,
      currency: "USD",
      fontColor: "#5B63FF",
      fontSize: "14px",
      textAlign: "center",
    };

    this.affirmMessageElement = elements.create("affirmMessage", options);
    this.affirmMessageElement.mount(this.messageTarget);
  }

  updateAmount(e) {
    const amount = parseInt(e.currentTarget.dataset.amount, 10);
    if (amount > 0) {
      this.affirmMessageElement.update({ amount: amount });
    }
  }
}
