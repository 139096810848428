import { Controller } from "stimulus";

import Cookies from "js-cookie";

export default class extends Controller {
  static targets = ["dismiss", "dismissable"];
  static values = { cookie: String };

  initialize() {
    if (Cookies.get(this.cookieValue) !== "true") {
      this.dismissableTarget.classList.remove("hidden");
    }
  }

  dismiss() {
    Cookies.set(this.cookieValue, "true", { expires: 365 });
    this.dismissableTarget.remove();
  }
}
