import React, { useContext, useEffect } from "react";

import { MapContext } from "../Map";

export default function PLSBoundaryLayer({ geoJsonUrl }) {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) return;

    let hoveredPolygonId = null;

    map.addSource("plss", {
      type: "geojson",
      data: geoJsonUrl,
    });

    // add label layer, which comes from the same source but the property is "section_label":
    map.addLayer({
      id: "plss-label",
      type: "symbol",
      source: "plss",
      layout: {
        "text-font": ["Roboto Regular", "Noto Sans Regular"],
        "text-size": [
          "interpolate",
          ["cubic-bezier", 0.5, 0, 0.5, 1],
          ["zoom"],
          10,
          8,
          15,
          24,
        ],
        "text-field": ["get", "frstdivlab"],
        visibility: "visible",
      },
      paint: {
        "text-color": "#333",
        "text-halo-color": "#fff",
        "text-halo-width": 1,
      },
    });

    map.addLayer({
      id: "plss2",
      type: "line",
      source: "plss",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": "hsl(0, 0%, 7%)",
        "line-width": 1,
        "line-opacity": 0.2,
        "line-dasharray": [2, 4],
      },
    });

    map.addLayer({
      id: "plss3",
      type: "fill",
      source: "plss",
      paint: {
        "fill-color": "#56b3d2",
        "fill-opacity": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          0.25,
          0,
        ],
      },
    });

    map.on("mousemove", "plss3", (e) => {
      if (e.features.length > 0) {
        // console.log(e.features[0]);
        if (hoveredPolygonId !== null) {
          map.setFeatureState(
            { source: "plss", id: hoveredPolygonId },
            { hover: false }
          );
        }

        hoveredPolygonId = e.features[0].id;
        map.setFeatureState(
          { source: "plss", id: hoveredPolygonId },
          { hover: true }
        );
      }
    });

    map.on("click", "plss3", (e) => {
      if (e.features.length > 0) {
        const feature = e.features.find((f) => f.properties.type === "section");
        if (feature) {
          window.location = feature.properties.va_permalink;
        }
      }
    });
  }, [map]);

  return <></>;
}
