import { Controller } from "stimulus";

import PhotoSwipe from "photoswipe";
import "photoswipe/style.css";

export default class extends Controller {
  static values = { imageUrl: String };

  show() {
    this.pswp = new PhotoSwipe({
      preloadFirstSlide: false,
      dataSource: [{ src: this.imageUrlValue, width: 3035, height: 1990 }],
      initialZoomLevel: "fit",
      maxZoomLevel: 1,
      errorMsg: "The photo cannot be loaded",
    });

    this.pswp.init();
  }
}
