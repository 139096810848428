import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["referralSource", "otherReferralSource"];

  toggleOtherReferralSource() {
    let referralSource = this.referralSourceTarget.value;

    if (referralSource === "other") {
      this.otherReferralSourceTarget.classList.remove("hidden");
    } else {
      this.otherReferralSourceTarget.classList.add("hidden");
    }
  }
}
