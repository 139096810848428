import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("change", (event) =>
      this.checkboxClicked(event)
    );
  }

  checkboxClicked(event) {
    if (event.target.type === "checkbox") {
      this.element.submit();
    }
  }
}
