import React from "react";

export default function Legend({ ramp }) {
  return (
    <div className="z-[10000] absolute bottom-[2em] right-[1em] bg-white py-[5px] px-[10px] shadow-md rounded-[4px] leading-[18px]">
      {Object.keys(ramp).map((v, _) => {
        return (
          <div key={`${v}-${ramp[v]}`}>
            <span
              className="inline-block h-[10px] w-[10px] mr-1 border-radius-[20%]"
              style={{ backgroundColor: ramp[v] }}
            ></span>
            <span>{Number(v).toLocaleString()} photos</span>
          </div>
        );
      })}
    </div>
  );
}
