import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "payment",
    "errors",
    "form",
    "submit",
    "pointAgreementCheckbox",
  ];

  connect() {
    var stripe = Stripe(window.STRIPE_PUBLISHABLE_KEY);
    const clientSecret = this.data.get("client-secret");
    const returnUrl = this.data.get("return-url");

    const options = {
      clientSecret,
      appearance: {
        variables: {
          colorPrimary: "#56b3d2",
          colorText: "#333333",
          colorDanger: "#cc0000",
          fontFamily: "trade-gothic-next, system-ui, sans-serif",
          spacingUnit: "3px",
          fontSizeSm: "12px",
        },
      },
    };

    if (clientSecret == null) {
      return;
    }

    const elements = stripe.elements(options);

    const paymentElement = elements.create(`payment`);
    paymentElement.mount(this.paymentTarget);

    this.enablePayNowButton();

    paymentElement.on("change", (_event) => {
      this.enablePayNowButton();
    });

    let form = this.formTarget;
    form.addEventListener("submit", async (event) => {
      event.preventDefault();

      this.disablePayNowButton();
      this.clearErrors();
      let originalButtonText = this.submitTarget.value;
      this.submitTarget.value = "Processing...";

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: returnUrl,
        },
      });

      this.submitTarget.value = originalButtonText;

      if (error) {
        this.errorsTarget.classList.remove("hidden");
        this.errorsTarget.innerHTML = `Sorry, there was an error processing your payment: <strong>${error.message}</strong> Please check your card details and try again. If you are still experiencing issues, please contact us via email at service@vintageaerial.com or by phone at 888-402-6901.`;
      }
    });
  }

  clearErrors() {
    this.errorsTarget.classList.add("hidden");
    this.errorsTarget.innerHTML = "";
  }

  enablePayNowButton() {
    if (this.hasPointAgreementCheckboxTarget) {
      if (this.pointAgreementCheckboxTarget.checked) {
        this.submitTarget.disabled = false;
      } else {
        this.submitTarget.disabled = true;
      }
    } else {
      this.submitTarget.disabled = false;
    }
  }

  disablePayNowButton() {
    this.submitTarget.disabled = true;
  }
}
