import { Controller } from "stimulus";

import Swiper from "swiper/bundle";

import "swiper/css/bundle";

export default class extends Controller {
  static values = { options: Object };

  connect() {
    this.swiper = new Swiper(this.element, this.optionsValue);
  }

  disconnect() {
    this.swiper.destroy();
    this.swiper = undefined;
  }
}
